import schemaOrgJSONLD from './schemaOrg';

export function getAggregateRating() {
  // We know the fields exist, but we’ll do a safe parse just in case
  const ratingValueStr = schemaOrgJSONLD.aggregateRating?.ratingValue || '0';
  const reviewCountStr = schemaOrgJSONLD.aggregateRating?.reviewCount || '0';

  return {
    ratingValue: parseFloat(ratingValueStr),        // => 4.8 (number)
    reviewCount: parseInt(reviewCountStr, 10),      // => 6 (number)
  };
}
