// src/pages/frisco.tsx
// This serves at https://fortenotary.com/frisco

import SEO from '../components/common/seo/SEO';
import Breadcrumbs from '@/components/common/seo/Breadcrumbs';
import RalphBrooksProfile from '../components/profile/ralphbrooksprofile';

const FriscoPage = () => {
  const breadcrumbs = [
    { name: 'Main', url: '/' },
    { name: 'Frisco', url: '/frisco' }
  ];
  return (
    <>
      <SEO
        title="Frisco Mobile Notary"
        description="Learn more about Ralph Brooks, founder of Forte Notary. He is a mobile notary based out of Frisco, TX."
        includeOrg={true}
        canonicalUrl="https://www.fortenotary.com/ralphbrooks"
      />
      <Breadcrumbs url="https://fortenotary.com" breadcrumbs={breadcrumbs} />
      <RalphBrooksProfile />
    </>
  );
};

export default FriscoPage;