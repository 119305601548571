// src/components/profile/StarRating.tsx

import React from 'react';
import { css } from '@emotion/css';

interface StarRatingProps {
  rating: number;     // e.g., 4.8
  outOf?: number;     // typically 5
  reviewCount?: number; // e.g., 6
}

// Just for demonstration; tweak these as you like.
const starWrapper = css`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`;

const starStyle = css`
  width: 20px;
  height: 20px;
  margin-right: 2px;
`;

// A color close to Google's star rating
const googleStarColor = '#e2ba57';
const outlineColor = '#606060'; // a subtle gray for the star outline

const StarRating: React.FC<StarRatingProps> = ({
  rating,
  outOf = 5,
  reviewCount,
}) => {
  const stars = [];

  for (let i = 0; i < outOf; i++) {
    // For star #i, figure out how much of it should be filled
    const starNumber = i + 1;
    const fillPercentage = Math.min(Math.max(rating - (starNumber - 1), 0), 1);

    stars.push(
      <div key={i} className={starStyle}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* -- Star outline (kept invisible except for the fill) -- */}
          <path
            d="
              M12 2
              L15.09 8.26
              L22 9.27
              L17 14.14
              L18.18 21
              L12 17.77
              L5.82 21
              L7 14.14
              L2 9.27
              L8.91 8.26
              L12 2
              Z
            "
            fill="#606060" 
          />
          
          {/* -- Filled portion of the star (clip to show partial fill) -- */}
          <path
            d="
              M12 2
              L15.09 8.26
              L22 9.27
              L17 14.14
              L18.18 21
              L12 17.77
              L5.82 21
              L7 14.14
              L2 9.27
              L8.91 8.26
              L12 2
              Z
            "
            fill={googleStarColor}
            style={{
              clipPath: `inset(0 ${100 - fillPercentage * 100}% 0 0)`
            }}
          />
        </svg>
      </div>
    );
  }

  return (
    <div className={starWrapper}>
            <span
        style={{
          marginLeft: '6px',
          marginRight: '6px',
          fontSize: '0.9rem',
          color: googleStarColor,
        }}
      >
      {rating.toFixed(1)}
      </span>
      {'    '}
      {stars}
      <span
        style={{
          marginLeft: '6px',
          fontSize: '0.9rem',
          color: googleStarColor,
        }}
      >
        {reviewCount && ` (${reviewCount} reviews)`}
      </span>
    </div>
  );
};

export default StarRating;
