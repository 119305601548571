// src/components/common/seo/SEO.tsx
import { Helmet } from 'react-helmet';
import schemaOrgJSONLD, { SchemaOrgType } from './schemaOrg';
import { LocationGraphSchema } from './locationSchema';

interface SEOProps {
  title?: string; // Title of the page
  description?: string; // Description of the page
  url?: string; // URL of the page e.g. https://locations/fortenotary.com/tx/frisco/5013-madison-drive/notary
  includeOrg?: boolean; // Include contact information for httpss://fortenotary.com at the frisco location
  canonicalUrl?: string; // URL of the canonical page e.g. https://www.example.com/page
  locationGraph?: LocationGraphSchema; // Information for a https://locations.fortenotary.com page
}

const SEO: React.FC<SEOProps> = ({ title, description, url, includeOrg, canonicalUrl, locationGraph }) => {

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {url && <meta property="og:url" content={url} />}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      {includeOrg && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD as SchemaOrgType)}
        </script>
      )}

      {/* If locationGraph is provided, embed it in a script tag */}
      {locationGraph && (
        <script type="application/ld+json">
          {JSON.stringify(locationGraph)}
        </script>
      )}

      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}

    </Helmet>
  );
};

export default SEO;
