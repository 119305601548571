// src/pages/ralphbrooks.tsx
// This serves at https://fortenotary.com/ralphbrooks

import React from 'react';
import SEO from '../components/common/seo/SEO';
import Breadcrumbs from '@/components/common/seo/Breadcrumbs';
// Import the newly created shared profile component:
import RalphBrooksProfile from '../components/profile/ralphbrooksprofile';

const RalphBrooksPage: React.FC = () => {
  const breadcrumbs = [
    { name: 'Main', url: '/' },
    { name: 'Ralph Brooks', url: '/ralphbrooks' },

  ];
  return (
    <>
      <SEO
        title="Ralph Brooks | Mobile Notary who provides Notary Services in Frisco, TX"
        description="Learn more about Ralph Brooks, founder of Forte Notary. He offers professional mobile notary services across Dallas-Fort Worth."
        includeOrg={true}
        canonicalUrl="https://www.fortenotary.com/ralphbrooks"
      />
      <Breadcrumbs url="https://fortenotary.com" breadcrumbs={breadcrumbs} />
      <RalphBrooksProfile />
    </>
  );
};

export default RalphBrooksPage;
