import SEO from '../components/common/seo/SEO';
import Breadcrumbs from '@/components/common/seo/Breadcrumbs';
import AppHeaderMobile from '../components/header/AppHeaderMobile';
import AppHeaderFull from '../components/header/AppHeaderFull'; // Import AppHeaderFull
import { Link } from 'react-router-dom';

const BlogList = () => {
  const breadcrumbs = [
    { name: 'Main', url: '/' },
    { name: 'Blog', url: '/blog' }
  ];
  return (
    <>
      {/* org should be false. Org only shows for pages that talk about the organization */}
      <SEO
        title='Blog'
        description="Learn about new events that could affect your notarization."
        includeOrg={false}
        canonicalUrl='https://fortenotary.com/blog'
      />
      <div className="pricing-page p-8 bg-[#212A37] text-[#FFFFFC] min-h-screen flex flex-col">
        {/* breadcumbs section */}
        <Breadcrumbs url="https://fortenotary.com" breadcrumbs={breadcrumbs} />
        {/* Header Section */}
        <header className=" fixed top-0 left-0 right-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/80 backdrop-blur">
          <div className="hidden md:flex w-full">
            <AppHeaderFull />  {/* Use AppHeaderFull for desktop */}
          </div>
          <div className="md:hidden flex items-center justify-between w-full">

            <AppHeaderMobile />  {/* Use AppHeaderMobile for mobile */}
          </div>
        </header>

        {/* Main Content Section */}
        <main className="flex-grow flex items-center justify-center pt-20">
          <div className="pricing-card bg-[#2b3645] mt-8 p-8 max-w-lg w-full rounded shadow-md">
            <h1 className="text-3xl font-bold mb-6">Blogs</h1>
            <h2 className="text-xl mb-4">Browse our latest insights and updates on notarization and estate planning</h2>

            <ul className="list-disc list-inside">
              <li className="mb-4">
                <Link
                  to="/blog/legal/estate-planning-witness-requirements"
                  className="text-[#e2ba57] hover:underline"
                >
                  Texas Estate Planning: Comprehensive Witness & Notary Requirements for Attorneys
                </Link>
                <p className="text-sm mt-1 text-gray-300">Published on December 15, 2024 - Dive into the complexities of Texas witness and notary requirements for estate planning documents, ensuring your clients’ documents stand up in court.</p>
              </li>
              {/* Add more blog entries here as they become available */}
            </ul>


          </div>
        </main>
      </div>
    </>
  );
};

export default BlogList;
