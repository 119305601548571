// src/components/common/seo/Breadcrumbs.tsx

import React from 'react';


interface Breadcrumb {
    name: string;
    url: string;
}

interface BreadcrumbsProps {
    breadcrumbs: Breadcrumb[];
    renderLocation?: 'top' | 'bottom'; // New prop to control rendering location
    url?: string; // This is https://locations.fortenotary.com or https://www.fortenotary.com
}



const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs, url }) => {
    // If url is given, use that as the base. Otherwise use the default.
    const baseDomain = url || 'https://locations.fortenotary.com';

    const breadcrumbListSchema = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": breadcrumbs.map((breadcrumb, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "name": breadcrumb.name,
            "item": `${baseDomain}${breadcrumb.url}`
        }))
    };

    return (
        <>
            {/* Always render Schema.org data */}
            <script type="application/ld+json">
                {JSON.stringify(breadcrumbListSchema)}
            </script>
        </>
    );
};

export default Breadcrumbs;