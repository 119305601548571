import SEO from '../components/common/seo/SEO';
import Breadcrumbs from '@/components/common/seo/Breadcrumbs';
import AppHeaderMobile from '../components/header/AppHeaderMobile';
import AppHeaderFull from '../components/header/AppHeaderFull';

/**
 * ImmigrationLandingPage
 *
 * This page is designed as a one-page "landing page" for Immigration Notarization services,
 * based on the GPT recommendation (hero section, pain points, etc.).
 * 
 */
const ImmigrationLandingPage = () => {
  const breadcrumbs = [
    { name: 'Main', url: '/' },
    { name: 'Immigration', url: '/immigration' }
  ];
  return (
    <>
      <SEO
        title='Immigration'
        description="Forte Notary"
        includeOrg={false}
        canonicalUrl='https://fortenotary.com/immigration'
      />

      <div className="immigration-page bg-[#212A37] text-[#FFFFFC] min-h-screen flex flex-col">
        {/* breadcrumb section */}
        <Breadcrumbs url="https://fortenotary.com" breadcrumbs={breadcrumbs} />
        {/* HEADER SECTION */}
        <header className="fixed top-0 left-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/80 backdrop-blur z-[100]">
          <div className="hidden md:flex w-full">
            <AppHeaderFull />
          </div>
          <div className="md:hidden flex items-center justify-between w-full">
            <a href="/" className="text-[#FFFFFC] text-xl font-bold mr-auto">
              Forte Notary
            </a>
            <AppHeaderMobile />
          </div>
        </header>

        {/* MAIN CONTENT */}
        {/* Main content has been pulled into corresponding md file */}
        <main className="flex-grow mt-20 pt-20 pb-16 px-4 md:px-8">
          {/* HERO SECTION */}
          <section className="hero-section text-center md:text-left mb-16">
            <div className="container mx-auto flex flex-col md:flex-row items-center gap-6">
              <div className="md:w-1/2">
                <h1 className="text-3xl md:text-5xl font-bold leading-tight mb-4">
                  Under Construction
                </h1>
              </div>
            </div>
          </section>
        </main>

      </div>
    </>
  );
}

export default ImmigrationLandingPage;