
// served from https://fortenotary.com/locations

import React from 'react';
import SEO from '../components/common/seo/SEO';
import Breadcrumbs from '@/components/common/seo/Breadcrumbs';
import AppHeaderFull from '../components/header/AppHeaderFull';
import AppHeaderMobile from '../components/header/AppHeaderMobile';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';

const heroSectionStyle = css`
  background: url('/about/frisco-darkest-background-sharp-lossy.webp') center center / cover no-repeat;
  min-height: 40vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fffffc;
`;

const heroOverlayStyle = css`
  background-color: rgba(33, 42, 55, 0.7); /* #212A37 with 0.7 opacity */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const heroContentStyle = css`
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 2rem;
  max-width: 800px;
  width: 100%;
`;

const sectionWrapperStyle = css`
  background-color: #212a37;
  color: #fffffc;
  padding: 2rem 1rem;
`;

const headingGoldStyle = css`
  color: #e2ba57;
`;

const linkStyle = css`
  color: #e2ba57;
  text-decoration: underline;

  &:hover {
    color: #d1a94f;
  }
`;

const articleContentStyle = css`
  p {
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  h2, h3, h4 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #e2ba57;
  }

  h2 {
    font-size: 2rem;
    text-transform: uppercase;
    border-bottom: 1px solid #e2ba57;
    display: inline-block;
    padding-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.75rem;
    text-transform: uppercase;
  }

  h4 {
    font-size: 1.5rem;
  }

  a {
    color: #e2ba57;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const LocationsPage: React.FC = () => {
  const breadcrumbs = [
    { name: 'Main', url: '/' },
    { name: 'Locations', url: '/locations' }
  ];
  return (
    <>
      <SEO
        title="Forte Notary Locations"
        description="White-glove notary services for elite professionals. View our main location in Frisco, Texas."
        includeOrg={true}
        canonicalUrl="https://fortenotary.com/locations"
      />
      <Breadcrumbs url="https://fortenotary.com" breadcrumbs={breadcrumbs} />
      {/* Header */}
      <header className="fixed top-0 left-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/80 backdrop-blur z-[100]">
        {/* Desktop Header */}
        <div className="hidden md:flex w-full">
          <AppHeaderFull />
        </div>
        {/* Mobile Header */}
        <div className="md:hidden flex items-center justify-between w-full">
          <AppHeaderMobile />
        </div>
      </header>

      {/* HERO SECTION */}
      <section className={`${heroSectionStyle}`}>
        {/* <div className={heroOverlayStyle}></div> */}
        {/* <div className={heroContentStyle}> */}
        <div>
          <h1 className="text-4xl text-center md:text-5xl font-bold mb-4">
            Our Locations
          </h1>
          {/* <p className="text-xl md:text-2xl">
                        Where white-glove, on-demand notary services await you.
                    </p> */}
        </div>
      </section>

      {/* MAIN CONTENT SECTION */}
      <main className={sectionWrapperStyle}>
        <div className="container mx-auto mt-12 mb-16 px-4">
          <section className="mb-12">
            <h2 className={`text-3xl font-bold mb-6 ${headingGoldStyle}`}>
              White-Glove Experience at Your Convenience
            </h2>
            <p className="leading-relaxed mb-4">
              Forte Notary is proud to serve the broader Dallas-Fort Worth region
              from our primary location in Frisco, Texas.
            </p>
            <p className="leading-relaxed mb-4">
              Whether you’re an attorney overseeing complex estate documents,
              a title company finalizing a real estate transaction, or a busy professional
              needing a quick signature, we deliver a seamless, white-glove experience
              that meets the highest standards.
            </p>
          </section>

          <section className="mb-12">
            <h3 className={`text-2xl font-semibold mb-4 ${headingGoldStyle}`}>
              Frisco Location
            </h3>

            <div className="bg-[#2b3645] p-6 rounded shadow-md flex flex-col md:flex-row">
              {/* Location Address / Info */}
              <div className="md:w-2/3 md:pr-6 mb-4 md:mb-0">
                <h4 className="text-xl font-bold mb-2 text-[#e2ba57]">
                  5013 Madison Drive <br /> Frisco, TX 75035
                </h4>
                <p className="mb-2">
                  <strong>Operating Hours:</strong> <br />
                  Monday-Sunday, 7:00AM – 11:00PM
                </p>
                <p className="mb-2">
                  <strong>Contact:</strong> <br />
                  <a href="tel:+14692968682" className={linkStyle}>
                    (469) 296-8682
                  </a>
                </p>
                <p className="mb-2">
                  <strong>Services:</strong> <br />
                  Mobile Notarizations, Loan Signings, Real Estate Closings,
                  Estate &amp; Trust Documents, and more.
                </p>
                <p className="mb-2">
                  <strong>Link to Page:</strong> <br />
                  <Link
                    to="https://locations.fortenotary.com/tx/frisco/5013-madison-drive/notary"
                    className={linkStyle}
                  >
                    View the 5013 Madison Drive Location
                  </Link>
                </p>
              </div>

              {/* Embedded Map or Future Space */}
              <div className="md:w-1/3">
                {/* If you want to embed a Google Map in the future:
                  <iframe 
                    src="GOOGLE_MAPS_EMBED_LINK_HERE"
                    style={{ width: '100%', height: '250px', border: 0 }}
                    loading="lazy"
                    allowFullScreen
                    title="Forte Notary - Frisco Map"
                  ></iframe>
                */}
                {/* <div className="p-4 border border-[#3a4758] rounded text-center">
                                    <p className="text-sm text-[#bfbfbf]">
                                        This space could be used for a map, directions,
                                        parking instructions, or additional location details.
                                    </p>
                                </div> */}
              </div>
            </div>
          </section>


          <section className={`mb-12 ${articleContentStyle}`}>
            <h3 className={`text-2xl font-semibold mb-4 ${headingGoldStyle}`}>
              Coming soon to a city near you!
            </h3>
            <p className="leading-relaxed mb-4">
              Forte Notary is coming soon to

              <ul className="mt-8">
                <li>Plano</li>
                <li>Prosper</li>
                <li>Little Elm</li>
                <li>Celina</li>
                <li>Allen</li>
                <li>Hackberry</li>
                <li>Cross Roads</li>
                <li>Oak Point</li>
                <li>Hebron</li>
                <li>Aubrey</li>
                <li>Krugerville</li>
              </ul>
            </p>

          </section>
        </div>
      </main>
    </>
  );
};

export default LocationsPage;
