import { FaBars, FaSearch } from 'react-icons/fa';
import { useState } from 'react';
import Submenu from './Submenu';

function AppHeaderMobile() {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const handleSubmenuOpen = () => {
    setIsSubmenuOpen(true);
  };

  const handleSubmenuClose = () => {
    setIsSubmenuOpen(false);
  };

  return (
    <>
      <a href="/"> 
      <img src="/header/forte-notary-logo-transparent-v2.svg" alt="Forte Notary Logo" className="h-14 w-14 mr-4 inline-block" />
        <span  className="text-[#FFFFFC] text-xl font-bold mr-auto">
          Forte Notary
        </span>
      </a>
      {/* <a href="/" className="text-[#FFFFFC] text-xl font-bold mr-auto">
        Forte Notary
      </a> */}
      {/* <FaSearch className="text-[#FFFFFC] mr-4" /> */}
      <FaBars className="text-[#FFFFFC] h-6 w-6" onClick={handleSubmenuOpen} />

      {/* Submenu Component */}
      <Submenu isOpen={isSubmenuOpen} onClose={handleSubmenuClose} title="Menu" />
    </>
  );
}

export default AppHeaderMobile;