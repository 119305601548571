// src/data/areaServed.ts

// "Frisco, Plano, McKinney, Prosper, Little Elm, Celina, Allen, Hackberry, Cross Roads, Oak Point, Hebron, Aubrey & Krugerville"
const areaServed = [
  "McKinney",
  "Allen",
  "Plano",
  "Celina",
  "Frisco",
  "Prosper",
  "Little Elm",
  "The Colony"
];

export default areaServed;
