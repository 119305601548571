import { css } from '@emotion/css';
import { useState } from 'react';

const panelStyle = css`
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  padding: 20px;

  &.visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  display: flex; /* Make the panel flex to have four columns */
  justify-content: space-between; /* Distribute columns evenly */
  gap: 20px; /* Add space between columns */

  width: calc(100vw - 40px); /* Center the panel by subtracting its padding */
  max-width: 800px; /* Set a max width */
  margin: 0 auto; /* Center the panel horizontally */

  /* At large screens (>=1096px), we center the panel on the screen, not anchored to the right anymore.
     We do this by overriding the position attributes. 
    TODO: This part still needs work.    
  */
     @media (min-width: 1096px) {
    left: 25%;
    right: auto;
    transform: translate(-50%, 10px);
  }

`;

const linkStyle = css`
  display: block;
  margin-bottom: 10px;
  color: #212A37; 
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
  &:hover {
    text-decoration: underline;
    color: #e2ba57;
  }
`;

const columnStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #212A37;
`;

const featuredColumnStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ccc;
  padding-left: 20px;
  color: #212A37;
`;

const featuredTitle = css`
  font-weight: bold;
  margin-bottom: 10px;
`;

const featuredImage = css`
  width: 100%;
  max-width: 100px; 
  height: auto;
  margin-bottom: 10px;
`;

const featuredLink = css`
  color: #212A37;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: #e2ba57;
  }
  &::after {
    content: '→';
    margin-left: 5px;
  }
`;

const ResourcePanel = ({ isVisible }: { isVisible: boolean }) => {
  return (

    <div className={`${panelStyle} ${isVisible ? 'visible' : ''}`}>
      {/* Column 1 */}
      <div className={columnStyle}>
        <a href="/resources" className={linkStyle}>
          Resources Library
        </a>
      </div>

      {/* Column 2 */}
      <div className={columnStyle}>
        <a href="/blog" className={linkStyle}>
          Blog
        </a>
      </div>

      {/* Column 3 (empty) */}
      <div className={columnStyle}>
        {/* Intentionally empty */}
      </div>

      {/* Column 4 (Featured) */}
      <div className={featuredColumnStyle}>
        <div className={featuredTitle}>Featured</div>
        {/* Replace this image URL with a relevant blog-related image */}
        {/* <img 
            src="/public/about/frisco-darkest-background-sharp-lossy.webp" 
            alt="Featured blog" 
            className={featuredImage} 
          /> */}
        {/* <div style={{ marginBottom: '10px', fontSize: '14px' }}>
          Learn why notaries are useful for will signings
        </div> */}
        <a href="/blog/legal/estate-planning-witness-requirements" className={featuredLink}>
          Learn about the roles of witnesses and notaries in will signings
        </a>
      </div>

    </div>
  );
};

export default ResourcePanel;
